import React, {Component} from 'react';

import './Comments.css';

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: "",
      type: "",
      id: 0,
      new_comment_name: "",
      new_comment_email: "",
      new_comment_body: "",
      new_comment_reply_id: 0,
      new_comment_saved: false,
      error: "",
      property: {
        threads: [],
      },
    };
    this.confirmCommentSaved = this.confirmCommentSaved.bind(this);
    this.cancelPost = this.cancelPost.bind(this);
    this.getCommentCount = this.getCommentCount.bind(this);
    this.getComments = this.getComments.bind(this);
    this.handlePostFormChange = this.handlePostFormChange.bind(this);
    this.handlePostFormSubmit = this.handlePostFormSubmit.bind(this);
  }

  componentDidMount() {
    console.log("this.dataset()", this.dataset());
    this.getCommentCount();
  }

  handlePostFormChange(e) {
    const t = e.target
    this.setState({
      [t.name]: t.value
    });
  }

  dataset() {
    const root = document.getElementById("comments-root");
    return root.dataset;
  }

  handlePostFormSubmit(e) {
    var data = new FormData();
    data.append('Authentication', process.env.REACT_APP_API_AUTH_KEY);
    data.append('key', this.dataset().key);
    data.append('type', this.dataset().type);
    data.append('id', this.dataset().id);
    data.append('name', this.state.new_comment_name);
    data.append('email', this.state.new_comment_email);
    data.append('body', this.state.new_comment_body);
    data.append('reply_id', this.state.new_comment_reply_id);
    fetch(process.env.REACT_APP_API_URL + "/thread/post", {
      method: 'POST',
      body: data,
    }).then(r => {
      return r.json();
    }).then(r => {
      console.log(r);
      if (typeof r.error !== "undefined") {
        this.setState({error: r.error});
      } else {
        this.getComments();
        this.setState({
          error: "",
          new_comment_name: "",
          new_comment_email: "",
          new_comment_body: "",
          new_comment_reply_id: 0,
          new_comment_saved: true,
        });
      }

    }).catch(error => {
      if (error.name === 'AbortError')
        return;
      throw error;
    });
    e.preventDefault();
  }

  getCommentCount() {
    var data = new FormData();
    data.append('Authentication', process.env.REACT_APP_API_AUTH_KEY);
    data.append('key', this.dataset().key);
    data.append('type', this.dataset().type);
    data.append('id', this.dataset().id);
    fetch(process.env.REACT_APP_API_URL + "/thread/count", {
      method: 'POST',
      body: data,
    }).then(r => {
      return r.json();
    }).then(r => {
      console.log(r);
      this.setState({...r});
    }).catch(error => {
      if (error.name === 'AbortError')
        return;
      throw error;
    });
  }

  getComments() {
    var data = new FormData();
    data.append('Authentication', process.env.REACT_APP_API_AUTH_KEY);
    data.append('key', this.dataset().key);
    data.append('type', this.dataset().type);
    data.append('id', this.dataset().id);
    fetch(process.env.REACT_APP_API_URL + "/thread", {
      method: 'POST',
      body: data,
    }).then(r => {
      return r.json();
    }).then(r => {
      console.log(r);
      this.setState({...r});
    }).catch(error => {
      if (error.name === 'AbortError')
        return;
      throw error;
    });
  }

  cancelPost() {
    this.setState({new_comment_reply_id: 0});
  }

  confirmCommentSaved() {
    this.setState({new_comment_saved: false});
  }

  renderPostForm() {
    return (
      <div className="PostForm" id="CommentPost">
          {!this.state.new_comment_saved ?
            <form onSubmit={this.handlePostFormSubmit}>
              {this.state.error !== null ?
                <div className="Error">{this.state.error}</div>
              : '' }
              <label>
                Name:
                <input name="new_comment_name" type="text" value={this.state.new_comment_name} onChange={this.handlePostFormChange} />
              </label>
              <label>
                Email:
                <input name="new_comment_email" type="text" value={this.state.new_comment_email} onChange={this.handlePostFormChange} />
              </label>
              <div>
                <label>
                  Comment:
                  <textarea name="new_comment_body" value={this.state.new_comment_body} onChange={this.handlePostFormChange} />
                </label>
              </div>
              {this.state.new_comment_reply_id !== 0 ?
                <div className="submitButton" onClick={this.cancelPost}>Cancel</div>
              : ""}
              <input type="submit" value="Submit" />
            </form>
          :
            <div className="CommentSaved">
              <div className="Message">Comment saved</div>
              <div className="Button" onClick={this.confirmCommentSaved}>Ok</div>
            </div>
          }
        </div>
    )
  }

  handleReplyClick = (i) => {
    this.setState({new_comment_reply_id: i});
  }

  render() {
    return (
      <div className="Comments">
        {this.state.new_comment_reply_id === 0 ?
          this.renderPostForm()
        : ""}
        <div className="Properties">
          {this.state.property.threads.length > 0 ?
            <div>
              {typeof this.state.property.threads[0].comment_count !== "undefined" ?
                <div className="LoadControl" onClick={this.getComments}>View {this.state.property.threads[0].comment_count} comments</div>
              : ""}
              {typeof this.state.property.threads[0].comments !== "undefined" ?
                <div>
                  <div className="SortControl">Sort: Latest First</div>
                  {this.state.property.threads[0].comments.sort(function (a, b) {
                      if (a.created_at > b.created_at) {
                        return -1;
                      }
                      if (a.created_at < b.created_at) {
                        return 1;
                      }
                      return 0;
                    }).map((comment, i) =>
                      <div key={"comment-"+comment.id} className="Comment">
                        <div className="Name">
                          {typeof comment.author_name !== "undefined" ?
                            comment.author_name
                          : 'Anonymous'}
                        </div>
                        {/* <div className="Date">
                          {comment.created_at}
                        </div> */}
                        <div className="Body">
                          {comment.body}
                        </div>
                        <div className="Controls">
                          {this.state.new_comment_reply_id !== comment.id ?
                            <div className="Reply" onClick={() => this.handleReplyClick(comment.id)}>Reply</div>
                          :
                            this.renderPostForm()
                          }
                        </div>
                      </div>
                    )}
                </div>
              : ''}
            </div>
          :
            <div className="LoadControl">0 comments</div>
          }
        </div>
      </div>
    );
  }
}

export default Comments;
